import React from 'react';
import About from '../Components/About/About';
import Accordion from '../Components/Accordion/Accordion';
import Appointment from '../Components/Appointment/Appointment';
import Contact from '../Components/Contact/Contact';
import Department from '../Components/Department/Department';
import Funfact from '../Components/Funfact/Funfact';
import MasonryGallery from '../Components/Gallery/Gallery';
import Hero from '../Components/Hero/Hero';
import Iconbox from '../Components/Iconbox/Iconbox';
import Newsletter from '../Components/Newsletter/Newsletter';
import BrandSlider from '../Components/Slider/BrandSlider';
import SpecialistsSlider from '../Components/Slider/SpecialistsSlider';
import TestimonialSlider from '../Components/Slider/TestimonialSlider';

const heroData = {
  bgImg: 'images/hero-bg.jpg',
  bgShape: 'shape/hero-shape.png',
  sliderImages: [
    {
      img: 'images/hero-img.png',
    },
    {
      img: 'images/hero-img1.png',
    },
    {
      img: 'images/hero-img2.png',
    },
    {
      img: 'images/hero-img.png',
    },
    {
      img: 'images/hero-img1.png',
    },
    {
      img: 'images/hero-img2.png',
    },
  ],
  title: ['Cardiology', 'Neurology', 'Dentist'],
};

const iconboxData = [
  {
    bg: 'purple',
    icon: 'icons/icon1.svg',
    title: 'Qualified Doctors',
    subTitle:
      'Our team of doctors are highly qualified and experienced in their respective fields.',
  },
  {
    bg: 'green',
    icon: 'icons/icon2.svg',
    title: '24 Hours Service',
    subTitle:
      'We provide round-the-clock services to ensure you get the care you need, whenever you need it.',
  },
  {
    bg: 'red',
    icon: 'icons/icon3.svg',
    title: 'Emergency Care',
    subTitle:
      'Our emergency services are available 24/7 to handle any urgent medical situations.',
  },
];
const aboutData = {
  title:
    'Our hospital is dedicated to providing top-notch healthcare services with a focus on patient satisfaction.',
  subTitle:
    'We offer a wide range of medical services and treatments, ensuring that each patient receives personalized care. Our state-of-the-art facilities and experienced staff are here to support you every step of the way. We believe in a holistic approach to healthcare, addressing not just the physical but also the emotional and psychological needs of our patients.',

  avater: {
    img: 'images/avatar1.png',
    name: 'David Ambrose',
    designation: 'Founder & Director',
  },
  timeTable: [
    {
      day: 'Monday',
      startTime: '8:00',
      endTime: '7:00',
    },
    {
      day: 'Tuesday',
      startTime: '9:00',
      endTime: '6:00',
    },
    {
      day: 'Wednesday',
      startTime: '9:00',
      endTime: '6:00',
    },
    {
      day: 'Thursday',
      startTime: '8:00',
      endTime: '7:00',
    },
    {
      day: 'Friday',
      startTime: '9:00',
      endTime: '5:00',
    },
    {
      day: 'Saturday',
      startTime: '8:00',
      endTime: '7:00',
    },
    {
      day: 'Sunday',
      startTime: '9:00',
      endTime: '3:00',
    },
  ],
  contact: '(+91) 9932024247',
};

const specialistData = [
  {
    img: 'images/pawanrawal.jpg',
    name: 'Dr. Pawan Rawal',
    designation: 'Artemis Hospital, Gurgaon',
    // authorLink: '/doctor-profile',
  },
  
  {
    img: 'images/DrBaba.jpg',
    name: 'Dr Bhaba Nanda Das',
    designation: 'Indraprastha Apollo Hospita',
    // authorLink: '/doctor-profile',
  },
  {
    img: 'images/DrRamesh.jpg',
    name: 'Dr. Ramesh Sarin',
    designation: 'Indraprastha Apollo Hospital, New Delhi',
    // authorLink: '/doctor-profile',
  },
  {
    img: 'images/drsandeep.jpg',
    name: 'Dr. Sandeep Vaishya',
    designation: 'Fortis Memorial Research Institute',
    // authorLink: '/doctor-profile',
  },
  {
    img: 'images/Mohamed.jpg',
    name: 'Prof. Dr. Mohamed Rela',
    designation: 'Rela Institute and Medical Centre',
    // authorLink: '/doctor-profile',
  },
  // {
  //   img: 'images/member2.jpg',
  //   name: 'Dr. Vera Hasson',
  //   designation: 'Cardiology',
  //   authorLink: '/doctor-profile',
  // },
  // {
  //   img: 'images/member3.jpg',
  //   name: 'Dr. Matthew Hill',
  //   designation: 'Neurosurgery',
  //   authorLink: '/doctor-profile',
  // },
  // {
  //   img: 'images/member4.jpg',
  //   name: 'Dr. Jeanette Hoff',
  //   designation: 'Surgery',
  //   authorLink: '/doctor-profile',
  // },
];

// const beforeAfterData = {
//   bgImg: '/images/before-after-bg.jpg',
//   beforeImg: '/images/after.jpg',
//   afterImg: 'images/before.jpg',
// };

const testimonialData = [
  {
    img: 'images/avatar2.png',
    name: 'Aakash Singh',
    designation: 'Worli Mumbai',
    subTitle:
      "The medical professionals at CareMedico provided top-notch care, and I felt confident in their expertise",
  },
  {
    img: 'images/avatar3.png',
    name: 'Rahul Sharma',
    designation: 'Delhi, India',
    subTitle:
      "They listened to my concerns and addressed them promptly, which I greatly appreciated",
  },
  {
    img: 'images/avatar4.png',
    name: 'Mohammad Haris',
    designation: 'Mumbai, India',
    subTitle:
      "They tailored their services to suit my individual requirements, which improved my overall experience.",
  },
  // {
  //   img: 'images/avatar2.png',
  //   name: 'RAFIN LIMON',
  //   designation: 'Dhaka, Bangladesh',
  //   subTitle:
  //     "Highly suggested. Very courteous and welcoming approach on the part of the staff.I was little confused, but after speaking with Hrituaparna (Case Manager) directly, Now I can trust caremedico and recommend others.",
  // },
  // {
  //   img: 'images/avatar3.png',
  //   name: 'Francis Jara',
  //   designation: 'Biographer',
  //   subTitle:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  // },
  // {
  //   img: 'images/avatar4.png',
  //   name: 'David Baer',
  //   designation: 'UX Designer',
  //   subTitle:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  // },
];

// const priceData = [
//   {
//     title: 'Blood Test',
//     price: '39',
//     featureList: [
//       {
//         title: 'First Description',
//         status: '1',
//       },
//       {
//         title: 'Second Description',
//         status: '1',
//       },
//       {
//         title: 'Third Description',
//         status: '1',
//       },
//       {
//         title: 'Fourth Description',
//         status: '0',
//       },
//       {
//         title: 'Fifth Description',
//         status: '0',
//       },
//     ],
//   },
//   {
//     title: 'Hemoglobin Test',
//     price: '89',
//     featureList: [
//       {
//         title: 'First Description',
//         status: '1',
//       },
//       {
//         title: 'Second Description',
//         status: '1',
//       },
//       {
//         title: 'Third Description',
//         status: '1',
//       },
//       {
//         title: 'Fourth Description',
//         status: '1',
//       },
//       {
//         title: 'Fifth Description',
//         status: '0',
//       },
//     ],
//   },
//   {
//     title: 'Homocysteine Test',
//     price: '150',
//     featureList: [
//       {
//         title: 'First Description',
//         status: '1',
//       },
//       {
//         title: 'Second Description',
//         status: '1',
//       },
//       {
//         title: 'Third Description',
//         status: '1',
//       },
//       {
//         title: 'Fourth Description',
//         status: '1',
//       },
//       {
//         title: 'Fifth Description',
//         status: '1',
//       },
//     ],
//   },
//   {
//     title: 'Blood Test',
//     price: '39',
//     featureList: [
//       {
//         title: 'First Description',
//         status: '1',
//       },
//       {
//         title: 'Second Description',
//         status: '1',
//       },
//       {
//         title: 'Third Description',
//         status: '1',
//       },
//       {
//         title: 'Fourth Description',
//         status: '0',
//       },
//       {
//         title: 'Fifth Description',
//         status: '0',
//       },
//     ],
//   },
//   {
//     title: 'Hemoglobin Test',
//     price: '89',
//     featureList: [
//       {
//         title: 'First Description',
//         status: '1',
//       },
//       {
//         title: 'Second Description',
//         status: '1',
//       },
//       {
//         title: 'Third Description',
//         status: '1',
//       },
//       {
//         title: 'Fourth Description',
//         status: '1',
//       },
//       {
//         title: 'Fifth Description',
//         status: '0',
//       },
//     ],
//   },
//   {
//     title: 'Homocysteine Test',
//     price: '150',
//     featureList: [
//       {
//         title: 'First Description',
//         status: '1',
//       },
//       {
//         title: 'Second Description',
//         status: '1',
//       },
//       {
//         title: 'Third Description',
//         status: '1',
//       },
//       {
//         title: 'Fourth Description',
//         status: '1',
//       },
//       {
//         title: 'Fifth Description',
//         status: '1',
//       },
//     ],
//   },
// ];

const faqData = {
  title: 'Frequently Asked Questions',
  img: 'images/faq-img.png',
  bgImg: 'shape/faq-bg.svg',
  faqItems: [
    {
      title: 'What is included in the end-to-end services provided by Caremedico?',
      content: `Caremedico offers comprehensive services including medical consultations, treatment planning, travel arrangements, accommodation, local transportation, and post-treatment follow-up.`,
    },
    {
      title: 'Can you help us with VISA application?',
      content: `Yes, we assist with the VISA application process, providing the necessary documentation and guidance to ensure a smooth application.`,
    },
    {
      title: 'How do I choose the right hospital and doctor?',
      content: `Our team provides detailed information about our partner hospitals and doctors, including their credentials and specialties, to help you make an informed decision.`,
    },
    {
      title: 'What kind of support is available during my stay?',
      content: `We offer 24/7 support during your stay, including a dedicated care manager, language translation services, and assistance with any local needs.`,
    },
    {
      title: 'How do I handle post-treatment care once I return home?',
      content: `We coordinate with your local healthcare providers to ensure a seamless transition and provide follow-up consultations with your treating doctors as needed.`,
    },
  ],
};

const newsletterData = {
  bgImg: 'images/news-letter-bg.png',
  contact: '(+91) 9932024247',
};

// const postData = [
//   {
//     img: 'images/blog1.jpg',
//     title: 'Working in emergency medicine',
//     date: 'Aug 23, 2020',
//     author: 'Albert Brian',
//     authorLink: '',
//     subTitle:
//       "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
//     postLink: '/post/post_details',
//   },
//   {
//     img: 'images/blog2.jpg',
//     title: 'Individual treatment & assistance',
//     date: 'Aug 22, 2020',
//     author: 'William Juarez',
//     authorLink: '',
//     subTitle:
//       "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
//     postLink: '/post/post_details',
//   },
//   {
//     img: 'images/blog3.jpg',
//     title: 'Child’s first hospital visit',
//     date: 'Aug 21, 2020',
//     author: 'Jamse Lewis',
//     authorLink: '',
//     subTitle:
//       "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
//     postLink: '/post/post_details',
//   },
//   {
//     img: 'images/blog3.jpg',
//     title: 'Child’s first hospital visit',
//     date: 'Aug 21, 2020',
//     author: 'Jamse Lewis',
//     authorLink: '',
//     subTitle:
//       "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
//     postLink: '/post/post_details',
//   },
// ];

const brandData = [
  {
    bg: 'orange',
    img: 'images/client1.png',
  },
  {
    bg: 'blue',
    img: 'images/client2.png',
  },
  {
    bg: 'red',
    img: 'images/client3.png',
  },
  {
    bg: 'green',
    img: 'images/client4.png',
  },
  {
    bg: 'dip-blue',
    img: 'images/client5.png',
  },
  {
    bg: 'orange',
    img: 'images/client1.png',
  },
  {
    bg: 'blue',
    img: 'images/client2.png',
  },
  {
    bg: 'red',
    img: 'images/client3.png',
  },
  {
    bg: 'green',
    img: 'images/client4.png',
  },
  {
    bg: 'dip-blue',
    img: 'images/client5.png',
  },
];

// const mapLocationURL =
//   'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d193175.30893635444!2d-74.373409!3d40.841927!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a82f1352d0dd%3A0x81d4f72c4435aab5!2sTroy%20Meadows%20Wetlands!5e0!3m2!1sen!2sbd!4v1701067943819!5m2!1sen!2sbd';

const Home = () => {
  return (
    <>
      <Hero data={heroData} />
      <Iconbox data={iconboxData} />
      <About data={aboutData} />
      <Department />
      <Appointment />
      <SpecialistsSlider data={specialistData} />
      <hr />
      <MasonryGallery />
      {/* <BeforeAfter data={beforeAfterData} /> */}
      <TestimonialSlider data={testimonialData} />
      <Funfact />
      {/* <PriceSlider data={priceData} /> */}
      <Accordion data={faqData} />
      <Newsletter data={newsletterData} />
      {/* <PostWrapper data={postData} /> */}
      <BrandSlider data={brandData} />
      <Contact />
      {/* <LocationInMap data={mapLocationURL} /> */}
    </>
  );
};

export default Home;
