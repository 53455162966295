import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import PageNotFound from './Components/404/PageNotFound';
import Layout from './Components/Layout/Layout';
import DoctorProfilePage from './Pages/DoctorProfilePage';
import Home from './Pages/Home';
import PostDetails from './Pages/PostDetails';

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        {/* <Route path="home2" element={<Home2 />} />
        <Route path="home3" element={<Home3 />} />
        <Route path="home4" element={<Home4 />} />
        <Route path="home5" element={<Home5 />} />
        <Route path="home6" element={<Home6 />} />
        <Route path="home7" element={<Home7 />} />
        <Route path="home8" element={<Home8 />} />
        <Route path="home10" element={<Home10 />} />
        <Route path="home12" element={<Home12 />} />
        <Route path="home13" element={<Home13 />} /> */}
        <Route path="/doctor-profile" element={<DoctorProfilePage />} />
        <Route path="post/:postId" element={<PostDetails />} />
        <Route path="*" element={<PageNotFound />} />

      </Route>

      {/* <Route path="/home11" element={<Layout2 />}>
        <Route index element={<Home11 />} />
      </Route>

      <Route path="/home9" element={<Layout3 />}>
        <Route index element={<Home9 />} />
      </Route>

      <Route path="/home14" element={<Layout4 />}>
        <Route index element={<Home14 />} />
        <Route path="doctor-profile2" element={<DoctorProfilePage2 />} />
      </Route>

      <Route path="/home15" element={<Layout5 />}>
        <Route index element={<Home15 />} />
        <Route path="doctor-profile3" element={<DoctorProfilePage3 />} />
      </Route>

      <Route path="/home16" element={<Layout6 />}>
        <Route index element={<Home16 />} />
        <Route path="doctor-profile2" element={<DoctorProfilePage2 />} />
      </Route>

      <Route path="/multipage" element={<Layout7 />}>
        <Route index element={<Multipage />} />
        <Route path='home' element={<Multipage />} />
        <Route path='about' element={<AboutPage />} />
        <Route path='gallery' element={<GalleryPage />} />
        <Route path='pricing' element={<PricingPage />} />
        <Route path="post" element={<PostPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="post/:postId" element={<PostDetails />} />
      </Route>

      <Route path="/multipage2" element={<Layout8 />}>
        <Route index element={<Multipage2 />} />
        <Route path='about' element={<AboutPage />} />
        <Route path='gallery' element={<GalleryPage />} />
        <Route path='pricing' element={<PricingPage />} />
        <Route path="post" element={<PostPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="post/:postId" element={<PostDetails />} />
      </Route>

      <Route path="/multipage3" element={<Layout9 />}>
        <Route index element={<Multipage3 />} />
        <Route path='about' element={<AboutPage />} />
        <Route path='gallery' element={<GalleryPage />} />
        <Route path='pricing' element={<PricingPage />} />
        <Route path="post" element={<PostPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="post/:postId" element={<PostDetails />} />
      </Route> */}
    </Routes>
  );
};

export default App;
